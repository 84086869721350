import { Button } from 'antd'
import React from 'react'

import styles from './success-button.module.less'
import {ButtonProps} from "antd/lib/button/button";
import clsx from "clsx";

import { motion } from "framer-motion"

type SuccessButtonProps = Omit<ButtonProps, "type"> & {withShadow?: boolean}

const SuccessButton = ({className, withShadow = false, block, ...rest}: SuccessButtonProps) => {
    return (
      <motion.div whileTap={{ scale: 0.8}} className={clsx(styles.container,block && styles.block)}>
          <Button type="primary" {...rest} block={block} className={clsx(styles.root, withShadow && styles.withShadow, className)} />
      </motion.div>
    )
}

export {SuccessButton}