import React from 'react'

import styles from './error.module.less'
import {Centered} from "@packages/components/layout/centered";
import {Button, Result} from "antd";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

type ErrorProps = {}

const Error = ({}: ErrorProps) => {
    const { t } = useTranslation()
    return (
        <Centered>
            <Result
                status="500"
                title="500"
                subTitle={t('error.header')}
                extra={<Link to={'/'}>
                    <Button type="primary">{t('error.back')}</Button>
                </Link>}
            />
        </Centered>
    )
}

export {Error}