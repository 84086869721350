import React, {ReactNode} from 'react'

import styles from './typography.module.less'
import clsx from "clsx";

export type TypoProps = {
    children: ReactNode
    className?: string
}

export type TypoPropsWithSize = {
    size?: 'normal' | 'large' |  'small' | 'very-large'
    block?: boolean
} & TypoProps

type Variants = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span";

const typoWithStyles = (typoStyles: string, variant: Variants = 'span') => {
    const Tag = `${variant}` as keyof JSX.IntrinsicElements;
    return ({children, className, size = 'normal'}: TypoPropsWithSize) => {
        return (
            <Tag className={clsx(styles.common, typoStyles, className, size )} >
                {children}
            </Tag>
        )
    }
}

export const PageHeader = typoWithStyles(styles.pageHeader, "h1")
export const PageSubheader = typoWithStyles(styles.pageSubheader, "h2")

export const Title = typoWithStyles(styles.boxHeader)
export const Label = typoWithStyles(styles.boxLabel)
export const Number = typoWithStyles(styles.number)

