import i18n, {initi18n} from "@packages/commons/src/i18n/config";
import translationsEn from './locales/en/translations.json'
import translationsPl from './locales/pl/translations.json'
import commonEn from '@packages/commons/src/i18n/locales/en/translations.json'
import commonPl from '@packages/commons/src/i18n/locales/pl/translations.json'

export const init = () => initi18n({
    ns: ['translations', 'common'],
    resources: {
        en: {
            translations: translationsEn,
            common: commonEn,
        },
        pl: {
            translations: translationsPl,
            common: commonPl,
        }
    }
})

export default i18n