import React from "react";

import {Button} from 'antd'

import styles from './app.module.less'
import { Breadcrumb, Layout, Menu } from 'antd';
import { ReactComponent as LogoIcon } from '@packages/components/icons/logo.svg';
import {SuccessButton} from "@packages/components/common/success-button";
import {PageHeader, PageSubheader} from "@packages/components/typography";
import {BackButton} from "@packages/components/common/back-button";
import {Link} from "react-router-dom";

const { Header, Content, Footer } = Layout;

const App = () => {
    return (
        <Layout>
            <Header className={styles.header} style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
                <section className={styles.logoContainer}>
                    <LogoIcon className={styles.logo} />
                    GEO RIDDLE
                </section>
                <a href='https://user.georiddle.com'>
                    <BackButton>Zaloguj</BackButton>
                </a>
            </Header>
            <Content className={styles.content} style={{ padding: '0 50px', marginTop: 64 }}>
                <PageHeader size={"very-large"}>Georiddle</PageHeader>
                <PageSubheader size="large" className={styles.subHeader}>
                    Zwiększ zaangażowanie Twoich zwiedzających i dostarcz im nowych, ciekawych wrażeń.
                </PageSubheader>
                <a href='https://user.georiddle.com'>
                    <SuccessButton>Wypróbuj</SuccessButton>
                </a>
            </Content>
            <Footer className={styles.footer} style={{ textAlign: 'center' }}>Georiddle  ©2022 Created by Georiddle sp. z.o.o</Footer>
        </Layout>
    )
};

export default App;
