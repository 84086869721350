import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

export const initi18n = (options: Partial<InitOptions>) => {
    i18n.use(initReactI18next).init({
        fallbackLng: 'en',
        lng: 'pl',
        defaultNS: 'translations',
        ...options
    });

    i18n.languages = ['en', 'pl'];
}

export default i18n;