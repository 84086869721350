import {IS_PRODUCTION} from "@packages/commons/src/env";
import React from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {useTranslation} from "react-i18next";
import {Error} from "@packages/components/status/error";

export const withSentry = (Component: React.ComponentType, key: string) => {
    if (!IS_PRODUCTION) {
        return Component
    }

    Sentry.init({
        dsn: key,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    });

    return Sentry.withErrorBoundary(Component, {fallback: Error})
}