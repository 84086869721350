import React, {ReactNode} from "react";

import styles from './centered.module.less'

export type CenteredProps = {
    children: ReactNode
}

export const Centered = ({children}: CenteredProps) => {
    return <div className={styles.root}>
        {children}
    </div>
}

